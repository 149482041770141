import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { Store } from '@app/config/store';
import { FSLUG } from '@app/constant/features';
import { SFTP, SFTPService } from '@app/services/sftp.service';
import { HeaderComponent } from '@app/shared/header/header.component';
import { Menu, SidenavComponent } from '@app/shared/sidenav/sidenav.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterUploadComponent } from './master-upload/master-upload.component';

@Component({
  selector: 'app-garnet',
  standalone: true,
  imports: [RouterOutlet, RouterModule, HeaderComponent, SidenavComponent, MasterUploadComponent],
  templateUrl: './garnet.component.html',
  styleUrl: './garnet.component.css',
})
export class GarnetComponent implements OnInit {
  @ViewChild('updateMaster') updateMaster!: TemplateRef<HTMLElement>;

  loading: boolean = true;
  sftpInfo!: SFTP;

  constructor(
    protected store: Store,
    private ngbModal: NgbModal,
    private sftpService: SFTPService,
  ) {}

  ngOnInit(): void {
    this.sftpService.getSFTPInfo().subscribe((resp) => {
      if (resp) this.sftpInfo = resp;
      this.loading = false;
    });
  }

  sidenavMenu: Menu[] = [
    // {
    //   title: '',
    //   route: '',
    //   icon: '',
    //   menu: [
    //     {
    //       title: 'Dashboard',
    //       route: '/garnet/dashboard',
    //       icon: 'matDashboardOutline',
    //       class: '!tw-mt-1',
    //       access: true,
    //     },
    //   ],
    // },
    {
      title: 'Workflow',
      route: '',
      icon: '',
      menu: [
        {
          title: 'Workflows',
          route: '/garnet/workflows',
          icon: 'matAccountTreeOutline',
          access: this.store.userAccess()[FSLUG.GARNET_WORKFLOWS_VIEW],
        },
        {
          title: 'Transform',
          route: '/garnet/output/history',
          icon: 'matTransformOutline',
          access: this.store.userAccess()[FSLUG.GARNET_TRANSFORM_VIEW],
        },
      ],
    },
    {
      title: 'Utilities',
      route: '',
      icon: '',
      menu: [
        {
          title: 'Update Master',
          icon: 'matUploadFileOutline',
          access: this.store.userAccess()[FSLUG.SETTINGS_SFTP_MODIFY],
          click: () => {
            this.ngbModal.open(this.updateMaster, {
              backdrop: 'static',
              centered: true,
            });
          },
        },
      ],
    },
    {
      title: 'Settings',
      route: '',
      icon: '',
      menu: [
        {
          title: 'SFTP',
          icon: 'matOpenInNew',
          access: this.store.userAccess()[FSLUG.SETTINGS_SFTP_MODIFY],
          click: () => window.open(`/account/sftp`, '_blank'),
        },
      ],
    },
  ];
}
